.chip {
  display: flex;
  flex-direction: row;
  border: 0.5px solid #e6e6e6;
  cursor: default;
  outline: none;
  padding: 0 16px;
  height: 35px;
  white-space: nowrap;
  align-items: center;
  border-radius: 35px;
  vertical-align: middle;
  text-decoration: none;
  justify-content: center;

  font-family: NotoSansKR-Regular;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: left;
  color: #202020;
}

.chip-head {
  font-weight: bold;
  cursor: inherit;
  white-space: nowrap;
}

.chip-content {
  padding: 0 12px;
  cursor: inherit;
  display: flex;
  align-items: center;
  /* white-space: pre-wrap; */
}

.chip-close {
  display: flex;
  position: relative;
  overflow: hidden;
  background-color: #ffffff;
  flex-shrink: 0;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  width: 18px;
  height: 18px;
  color: #858585;
  cursor: pointer;
  font-size: 14px;
}

.chip-close:hover {
  background-color: #eeeeee;
}


@media (max-width: 540px) {
  .chip {
    height: auto;
    min-height: 35px;
  }
  .chip-content {
    white-space: pre-wrap;
  }
}